<template>

<div>

  欢迎登录管理系统后台
</div>

</template>
<script>

export default {

  name: 'WelecomIndex',

  created () {

  }

}

</script>
