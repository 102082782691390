<template>

  <div>

    <el-divider><div class="title">单词列表</div> </el-divider>
    <div><el-button    @click="showAddAdminDialog"        type="primary">添加单词</el-button></div>

    <el-table
    border
    :data="tableData"
    style="width: 100%"
    :row-class-name="tableRowClassName">
    <el-table-column  lable="编号" width="120">

      <template slot-scope="scope">
  {{ scope.row.id }}
</template>
    </el-table-column>
    <el-table-column
      prop="name"
      label="中文"
      width="200">
    </el-table-column>

    <el-table-column
      prop="ename"
      label="英文释义"
      width="500">

    </el-table-column>

    <el-table-column
     prop="time"
     label="添加时间"
     width="200">
   </el-table-column>

<el-table-column

  label="操作"
  width="220">

  <template slot-scope="scope">
    <el-button type="danger" icon="el-icon-delete"       @click="delword(scope.row)">删除</el-button>
        <el-button type="primary" icon="el-icon-edit"       @click="handleEdit(scope.row)">修改</el-button>
      </template>

</el-table-column>
  </el-table>

  <!-- 添加词语对话框 -->
  <el-dialog title="添加词语" :visible.sync="addAdminDialogVisible">
      <el-form :model="newAdminForm"  :rules="rules"  ref="loginForm"       label-width="80px">
        <el-form-item label="中文" prop="name">
          <el-input v-model="newAdminForm.name"></el-input>
        </el-form-item>

        <el-form-item label="对应英文" prop="ename">
          <el-input v-model="newAdminForm.ename" type="text"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addAdminDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="addword">提交</el-button>
      </div>
    </el-dialog>
<!-- 添加词语结束 -->

<!-- 修改管理员数据开始 -->
<el-dialog title="修改词语" :visible.sync="updatashow">
      <el-form :model="updataForm"  :rules="updaterules"   ref="loginForm"       label-width="80px">
        <el-form-item label="中文" prop="name">
          <el-input v-model="updataForm.name"></el-input>
        </el-form-item>

        <el-form-item label="英文释义" prop="ename">
          <el-input v-model="updataForm.ename" type="ename"></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatashow = false">取消</el-button>
        <el-button type="primary" @click="updataword">提交</el-button>
      </div>
    </el-dialog>

<!-- 修改词语结束 -->

<!-- 分页开始 -->
<!-- 分页 -->
<el-pagination
background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size=pagesize
      layout="total, prev, pager, next"
      :total="totalcount">
    </el-pagination>

<!-- 分页结束 -->
  </div>

  </template>

<script>
export default {
  data () {
    return {

      showPageStart: 0, // 当前页显示的序号起始值
      currentpage: 1, // 当前页码
      totalcount: 20, // 总数据条数
      pagesize: 10, // 每页显示的数据条数
      rules: {
        name: [{ required: true, message: '请输入中文', trigger: 'blur' }],
        ename: [{ required: true, message: '请输入英文释义', trigger: 'blur' }]
      },
      updaterules: {
        name: [{ required: true, message: '请输入中文', trigger: 'blur' }],
        ename: [{ required: true, message: '请输入英文解释', trigger: 'blur' }]
      },
      tableData: [], // 列表单词数据
      addAdminDialogVisible: false, // 添加管理员对话框是否显示
      updatashow: false, // 跟新用户信息的时候弹框是否显示
      newAdminForm: { // 新管理员表单数据
        name: '', // 中文
        ename: '' // 英文
      },
      updataForm: {
        name: '', // 中文
        ename: '', // 英文
        id: '' // 跟新单词的id
      },
      value: true // 按钮切换
    }
  },
  created () {
    this.getword() // 调用获取管理员信息
  },
  methods: {

    // 分页页码改变监听函数
    handleCurrentChange (newpage) {
      console.log(newpage)
      this.currentpage = newpage
      this.getword()
    },

    showAddAdminDialog () {
      this.addAdminDialogVisible = true
    },
    // 编辑跟新老师信息
    handleEdit (item) {
      console.log('点击了')
      this.updatashow = true
      console.log(item)
      this.updataForm.name = item.name
      this.updataForm.ename = item.ename
      this.updataForm.id = item.id
    },
    // 删除单词
    delword (item) {
      console.log(item.id)

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 发请求删除
        this.$axios.get('/delword',
          {
            params: {
              id: item.id
            }
          }
        )
          .then(response => {
            console.log(response)
            if (response.data.code === 200) {
              console.log('删除成功')
              this.getword() // 删除以后重新获取一遍数据渲染
            } else {
              this.$message.error(response.data.message)
            }
          })
          .catch(error => {
            console.error(error)
            this.$message.error('添加单词失败')
          })

        // 发请求删除结束
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 添加单次
    addword () {
      // 首先进行表单验证
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // 提交表单数据，这里可以发送网络请求将数据提交到后台

          this.$axios.post('/addword', this.newAdminForm)
            .then(response => {
              if (response.data.success) {
                this.$message({
                  message: '添加单词成功',
                  type: 'success'
                })
                // 清除表单
                this.newAdminForm.name = ''
                this.newAdminForm.ename = ''
                this.getword() // 重新获取数据
              } else {
                this.$message.error(response.data.message)
              }
            })
            .catch(error => {
              console.error(error)
              this.$message.error('添加单词失败')
            })
          // 隐藏对话框
          this.addAdminDialogVisible = false
        }
        // 这里可以添加成功后的逻辑处理，比如重新获取管理员列表数据等
      })
    },

    // 跟新单词信息

    updataword () {
      // 首先进行表单验证
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // 提交表单数据，这里可以发送网络请求将数据提交到后台

          this.$axios.post('/updataword', this.updataForm)
            .then(response => {
              console.log(response)
              if (response.data.code === 200) {
                this.$message({
                  message: '跟新用户成功',
                  type: 'success'
                })
                this.getword() // 重新获取数据
              } else {
                this.$message.error(response.data.message)
              }
            })
            .catch(error => {
              console.error(error)
              this.$message.error('跟新用户失败')
            })
          // 隐藏对话框
          this.updatashow = false
        }
      })
    },

    getword () {
      // 获取添加的单词信息
      this.$axios.get('/getword', {
        params: {
          page: this.currentpage,
          size: this.pagesize
        }
      })
        .then(response => {
          console.log(response)
          if (response.data.code === 200) {
            this.tableData = response.data.data.data
            this.totalcount = response.data.data.totalcount

            console.log(this.tableData)
          } else {
            // this.$message.error(response.data.message)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },

    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    }
  }

}
</script>

<style scoped>
.el-divider{
  margin-bottom: 40px;
}
.title{
  text-align: center;
  font-size: 30px;

}
.el-button{
margin: 10px 0;
}
.el-table{
  font-size: 18px;
  border: 3px solid #efefef;

}
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;

  }

</style>
