<template>
  <div class="login-container">
    <img class="logo" src="../assets/logo.png" alt="Logo">
    <div class="login-content">
      <h2 class="login-title">欢迎登录</h2>
      <el-form ref="loginForm" :model="loginForm" :rules="rules" class="login-form">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username"  name="username"   prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="loginForm.password" name="password" prefix-icon="el-icon-lock" type="password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login-button" @click="login">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
export default {
  name: 'LoginIndex',
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      token: '', // 用户登录凭证
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 登录方法
    // 登录方法，当用户点击登录按钮时触发
    login () {
      // 首先进行表单验证
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // 表单验证通过，发送登录请求
          this.$axios.post('/login', this.loginForm)
            .then(response => {
              if (response.data.code === 200) {
                // 处理登录成功的响应

                // 存储Token到localStorage
                localStorage.setItem('adminToken', response.data.token)
                this.$message({
                  showClose: true,
                  message: response.data.message,
                  type: 'success'
                })

                // 这里可以根据需要处理登录成功的逻辑，比如跳转到其他页面
                this.$router.push('/welcom')
              } else if (response.data.code === 400) {
                this.$message({
                  showClose: true,
                  message: response.data.message,
                  type: 'error'
                })

                console.error('登录失败', response.data.message)
              }
            })
            .catch(error => {
              // 处理登录失败的响应
              this.$router.push('/login')
              console.error('登录失败', error.response.data)
              // 这里可以提示用户登录失败信息，比如用户名或密码错误
            })
        } else {
          // 表单验证未通过，提示用户
          console.log('表单验证未通过')
          return false
        }
      })
    },
    // 登录方法

    setTokenHeader () {
      // 从localStorage中获取Token
      const token = localStorage.getItem('adminToken')
      if (token) {
        // 设置Token到请求头中
        this.$axios.defaults.headers.common.Authorization = 'Bearer ' + token
      }
    },

    created () {
    // 在组件创建时设置Token请求头
      this.setTokenHeader()
    }

  }
}
</script>

<style scoped>
.login-container {
  background-image: url('../assets/loginbgc.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 100px;
  height: auto;
}

.login-content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.login-title {
  margin-bottom: 20px;
  text-align: center;
}

.login-form {
  width: 100%;
}

.el-input {
  width: 100%;
}

.login-button {
  width: 100%;
}
</style>
