import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'

// 引入axios
import axios from 'axios'
// 配置请求的基地址
// axios.defaults.baseURL = 'http://lj.jiuzhouzhichuang.cn/index.php'
axios.defaults.baseURL = 'https://fanyi.jiuzhouzhichuang.net/index.php'

Vue.prototype.$axios = axios // 将axios挂载到Vue实例的原型链上

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
