<template>

    <div>

      <!-- <div><el-button    @click="showAddAdminDialog"        type="primary">添加学生</el-button></div> -->

      <el-table
      border
      :data="tableData"
      style="width: 100%"
      :row-class-name="tableRowClassName">
      <el-table-column  lable="编号" width="120">

        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>

      </el-table-column>
      <el-table-column
        prop="username"
        label="姓名"
        width="100">
      </el-table-column>
      <el-table-column
        prop="sex"
        label="性别"
        width="100">
      </el-table-column>
      <el-table-column
  prop="idcard"
  label="身份证号"
  width="220">
</el-table-column>

      <el-table-column
        prop="profession"
        label="专业"
        width="350"  >
      </el-table-column>

      <el-table-column
  prop="teacher"
  label="招生老师"
  width="100">
</el-table-column>

<el-table-column

  label="证件"
  width="180">

  <template slot-scope="scope">
    <img class="show-img"     :src="'http://lj.jiuzhouzhichuang.cn/storage/'+scope.row.image"     >
    </template>

</el-table-column>
<el-table-column
  prop="time"
  label="登记时间"
  width="150">
</el-table-column>

      <el-table-column

    label="状态"
    width="150">

  <template  slot-scope="scope">
    <el-button   v-if="scope.row.state"   disabled      type="primary" >已确认</el-button>
  <el-button     v-else   @click="changstate(scope.row.id)"         type="warning" >待确认</el-button>

  </template>

  </el-table-column>
  <!-- <el-table-column -->
    <!-- prop="name" -->
    <!-- label="操作" -->
    <!-- width="220"> -->

    <!-- <el-button type="primary" icon="el-icon-edit"></el-button> -->
  <!-- </el-table-column> -->
    </el-table>
<!-- 分页 -->
<el-pagination
background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size=pagesize
      layout="total, prev, pager, next"
      :total="totalcount">
    </el-pagination>

    <!-- 添加管理员对话框 -->
    <el-dialog title="添加学生" :visible.sync="addAdminDialogVisible">
        <el-form :model="newAdminForm" label-width="80px">
          <el-form-item label="用户名" prop="username">
            <el-input v-model="newAdminForm.username"></el-input>
          </el-form-item>
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="newAdminForm.realName"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="newAdminForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="上传头像" prop="avatar">
            <!-- 上传头像的组件 -->
            <el-upload
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="newAdminForm.phone"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addAdminDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addAdmin">提交</el-button>
        </div>
      </el-dialog>

    </div>

    </template>

<script>
export default {
  data () {
    return {
      showPageStart: 0, // 当前页显示的序号起始值
      currentpage: 1, // 当页码
      totalcount: 20, // 总数据条数
      pagesize: 4, // 每页显示的数据条数
      addAdminDialogVisible: false, // 添加管理员对话框是否显示
      newAdminForm: { // 新管理员表单数据
        username: '',
        realName: '',
        password: '',
        avatar: '', // 上传头像的地址
        phone: ''
      },
      imageUrl: '', // 上传头像后的预览地址
      value: true, // 按钮切换
      tableData: []
      // state: 0 // 学生录入确认状态
    }
  },
  created () {
    this.getallstudent() // 获取全部学生信息
  },
  methods: {

    // 分页页码改变监听函数
    handleCurrentChange (newpage) {
      console.log(newpage)
      this.currentpage = newpage
      this.getallstudent()
    },
    // 点击切换学生录取状态
    changstate (id) {
      console.log(id)
      this.$axios.get('/changestate', {

        params: {
          param: id
        }
      })
        .then(response => {
          console.log(response)
          if (response.data.code === 200) {
            this.$message({
              message: response.data.message,

              type: 'success'

            })
            this.getallstudent() // 重新刷新页面
          } else {
            this.$message.error(response.data.message)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    // 获取全部学生信息
    getallstudent () {
      this.$axios.get('/getallstudent', {
        params: {
          page: this.currentpage,
          size: this.pagesize
        }

      })
        .then(response => {
          console.log(response)
          if (response.data.code === 200) {
            this.tableData = response.data.data.data
            this.totalcount = response.data.data.totalcount
          } else {
            this.$message.error(response.data.message)
          }
        })
        .catch(error => {
          console.error(error)
        })
    },
    showAddAdminDialog () {
      this.addAdminDialogVisible = true
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.newAdminForm.avatar = res.url // 将上传成功后的头像地址保存到表单数据中
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        return false
      }
      return true
    },
    addAdmin () {
      // 提交表单数据，这里可以发送网络请求将数据提交到后台
      console.log('提交表单:', this.newAdminForm)
      // 清空表单数据
      this.newAdminForm = {
        username: '',
        realName: '',
        password: '',
        avatar: '',
        phone: ''
      }
      // 隐藏对话框
      this.addAdminDialogVisible = false
      // 这里可以添加成功后的逻辑处理，比如重新获取管理员列表数据等
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex === 1) {
        return 'warning-row'
      } else if (rowIndex === 3) {
        return 'success-row'
      }
      return ''
    }
  }

}
</script>

  <style scoped>
  .el-divider{
    margin-bottom: 40px;
  }
  .title{
    text-align: center;
    font-size: 30px;

  }
  .el-button{
  margin: 10px 0;
  }
  .el-table{
    font-size: 18px;
    border: 3px solid #efefef;

  }
  .el-table .warning-row {
      background: oldlace;
    }

    .el-table .success-row {
      background: #f0f9eb;

    }
    .show-img{
      width: 100px;
      height: 100px;
    }
.el-pagination{
  margin-top: 20px;
}
  </style>
