import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/components/Login.vue'
import Welcom from '@/components/Welcom.vue'
import AdminList from '@/components/AdminList.vue'
import StudentRegister from '@/components/StudentRegister'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },

    // 主管理员登录
    {
      path: '/login',
      component: Login
    },

    // 主管理员系统后台路由
    {
      path: '/home',
      component: () => import('../views/HomeView.vue'),
      redirect: '/welcom',
      children: [
        { path: '/welcom', component: Welcom },
        { path: '/adminlist', component: AdminList },
        { path: '/studentregister', component: StudentRegister }
      ]
    }

  ]

})
// 挂载路由导航卫士
router.beforeEach((to, from, next) => {
  // 从 localStorage 获取 token
  const token = localStorage.getItem('adminToken')

  // 用户访问登录页
  if (to.path === '/login') {
    // 如果已经有 token，重定向到欢迎页
    if (token) {
      return next('/welcom') // 假设这是欢迎页的路径
    }
    // 如果没有 token，允许访问登录页
    return next()
  }

  // 用户访问其他受保护的页面
  if (!token) {
    // 如果没有 token，重定向到登录页
    return next('/login')
  }

  // 允许访问其他页面
  next()
})

// router.beforeEach((to, from, next) => {
// to,将要去的路径
// from,从哪个路径跳转而来
// next是一个函数，表示放行
// if (to.path === '/login') return next()
//
// if (!localStorage.getItem('admintoken')) return next('/login')
//
// next()
// })
//
export default router
